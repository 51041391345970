<template>
    <v-container>
        <v-card v-if="paymentSuccess">
            <v-card-title>Payment Sucessful</v-card-title>
            <v-card-text>{{paymentSuccess}}</v-card-text>
            <v-card-actions></v-card-actions>
        </v-card>
        <v-card v-else-if="paymentError">
            <v-card-title>Payment Error</v-card-title>
            <v-card-text>{{paymentError}}</v-card-text>
            <v-card-actions></v-card-actions>
        </v-card>
        <v-card v-else-if="!clientSecret" :loading="loading">
            <v-card-title>Pay Now</v-card-title>
            <v-card-text>
                <v-text-field label="Name" v-model="name"></v-text-field>
                <v-text-field label="Email" type="email" v-model="email"></v-text-field>
                <v-text-field label="Amount" type="number" v-model="amount"></v-text-field>
                <v-select label="Currency" :items="['usd']" v-model="currency"></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="green" @click="getPaymentIntent">
                    <v-icon>mdi-credit-card-outline</v-icon>Next
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else :loading="loading">
            <v-card-title>Pay Now</v-card-title>
            <v-card-text>
                <strypeInput
                    :element="cardElement"
                    label="Credit / Debit Card"
                    :outlined="true"
                    @change="validity = $event"
                ></strypeInput>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    color="green"
                    @click="pay"
                    :disabled="!validity.complete || loading"
                >
                    <v-icon>mdi-credit-card-outline</v-icon>Pay Now
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
//https://stripe.com/docs/js/elements_object/create_element?type=card
import { loadStripe } from "@stripe/stripe-js";
import strypeInput from "./input.vue";
import gql from "graphql-tag";
export default {
    components: {
        strypeInput,
    },
    data() {
        return {
            name: null,
            email: null,
            amount: null,
            currency: null,
            clientSecret: null,
            loading: false,

            cardElement: null,
            validity: {},
            $_stripe: null,
            paymentError: null,
            paymentSuccess: null,
        };
    },
    methods: {
        async pay() {
            if (this.$_stripe && this.clientSecret) {
                try {
                    this.loading = true;

                    let result = await this.$_stripe.confirmCardPayment(
                        this.clientSecret,
                        {
                            payment_method: {
                                card: this.cardElement,
                                billing_details: {
                                    name: this.name,
                                    email: this.email,
                                },
                            },
                            receipt_email: this.email
                        }
                    );

                    if (result.error) {
                        this.paymentError = result.error;
                    }
                    if (
                        result.paymentIntent &&
                        result.paymentIntent.status === "succeeded"
                    ) {
                        this.paymentSuccess = result.paymentIntent;
                    }
                } catch (e) {
                    alert(e);
                } finally {
                    this.loading = false;
                }
            }
        },
        getPaymentIntent() {
            this.loading = true;
            this.$apollo
                .query({
                    query: gql`
                        query ($amount: ID!, $currency: String!) {
                            StrypePaymentIntent(
                                amount: $amount
                                currency: $currency
                            ) {
                                clientSecret
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        amount: this.amount,
                        currency: this.currency || "usd",
                    },
                })
                .then(({ data }) => {
                    if (data.StrypePaymentIntent?.clientSecret)
                        this.clientSecret =
                            data.StrypePaymentIntent.clientSecret;
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    async mounted() {
        this.$_stripe = await loadStripe(
            "pk_test_51Jc6pyIpGmBoJMy4No9XkPjKCdANquZVsCRDRDDDpaPyoC7ALhZkVA6xcTRinimAcamvM3rLZGmnmVONsElOaRBj00pPmBy3Bf"
        );
        let elements = this.$_stripe.elements();
        this.cardElement = elements.create("card", { showIcon: true });
    },
};
</script>