<template>
    <v-container>
        <payNow></payNow>
        
        <!--
            <SignBox></SignBox>
            <v-btn @click="cpts.push('test')" >ADD</v-btn>
        <v-btn @click="cpts.splice(0,1)" >Remove</v-btn>
        <cptMatrix :cpt_codes="cpts" v-model="cptmap" />
     
       {{cptmap}}
        
            <m2 :facility_id="1" />
        {{amodel}}
        <br />
        {{asearch}}
        <br />
        <v-combobox v-model="amodel" :items="aitems" :search-input.sync="asearch" clearable>
            <template v-slot:append-outer>
                <v-btn v-if="canadd" small color="primary" @click="add">ADD</v-btn>
            </template>
            <template v-slot:item="{item}">
                {{item.text}}
                <v-spacer></v-spacer>
                <v-btn v-on:click.stop="remove(item)" small color="error">X</v-btn>
            </template>
        </v-combobox>

        <br v-for="i in 10" :key="i" />
        
            <SignBox ></SignBox>
            <imageupload
            v-model="theImage"
            width="200"
            :placeholder="require('../assets/hc.png')"
            type="test"
            owner-id="1"
            owner-type="test"
        ></imageupload>
        {{theImage}}
        <v-select
            v-model="myCondition"
            :items="[null,1,2,3,4,5,6,7,8,9,10]"
            label="Select Condition"
        ></v-select>
        <autocomplete
            label="theLabel"
            model="Patient"
            no-data-text="hoho"
            :conditions="myCondition ? [{ id: { eq: myCondition } }] : null"
        ></autocomplete>
        <crud
            :headers="headers"
            show-expand
            model="PatientInsurance"
            label="Patient's Insurances"
            :conditions="myCondition ? [{ id: { eq: myCondition } }] : null"
        >
            <template v-slot:filters="{conditions, setConditions}">
                Filters
                <v-btn
                    v-on:click="toggleConditions(conditions, setConditions);"
                >{{conditions.length == 0 ? 'Hide Deleted' : 'Show ALL', }}</v-btn>
            </template>
            <template v-slot:item.btn="obj">
                <v-btn>{{obj.item.id}}</v-btn>
            </template>
            <template v-slot:expanded-item="obj">
                <v-btn>{{obj.item}}</v-btn>
            </template>
        </crud>
        <crud :headers="headers1" model="Patient" label="Patients"></crud>
        <crud :headers="headers2" model="File" label="Files"></crud>
        <v-container>
            <dwt />
        </v-container>-->
    </v-container>
</template>

<style scoped>
#signature_pad {
    width: 600px;
    height: 600px;
    border: 1px solid black;
    background-color: white;
}
</style>

<script>
//import m2 from "../views/facility/settings/cptCodesTest"
//import crud from "../components/CRUD.vue";
//import dwt from "../components/dwt.vue";
//import autocomplete from "../components/AutoComplete";
//import imageupload from "../components/ImageUpload";
//import SignBox from './LabtestNew/SignBox.vue'
//import cptMatrix from "../views/LabtestNew/cptMatrix";
import payNow from "../components/stripe/paymentForm.vue";
export default {
    components: {
        //m2
        //crud
        //dwt,
        //autocomplete,
        //imageupload,
        //SignBox
        //cptMatrix,
        //StripeCheckout,
        payNow,
    },
    methods: {


        add() {
            this.aitems.push({ value: null, text: this.asearch });
            this.asearch = null;
        },
        remove(i) {
            this.aitems.splice(this.aitems.indexOf(i), 1);
        },
        //getSignature() {
        //console.log(signaturePad.toDataURL());
        //},
        toggleConditions(current, func) {
            if (current.length > 0) {
                this.currentConditions = current;
                func([]);
            } else func(this.currentConditions);
        },
        getValue(obj, index) {
            //if(index.indexOf('.')>=0) {
            let parts = index.split(".");
            let res = obj;
            parts.forEach((v) => {
                res = res[v];
            });
            return res;
            //}
            //else return obj[index];
        },
    },
    watch: {
        asearch(n) {
            this.canadd = !!n && (!this.amodel || this.amodel.text != n);
        },
    },
    data() {
        return {
            cptmap: {},
            cpts: ["G0841", "88251", "87881"],
            canadd: false,
            aitems: [
                { value: 1, text: "hoho1" },
                { value: 2, text: "hoho2" },
                { value: 3, text: "hoho3" },
            ],
            amodel: null,
            asearch: null,
            theImage: 186,
            file: "hc",
            myCondition: null,
            currentConditions: null,
            headers: [
                // NEED TO IMPLEMENT accepting ITEMS as array of objects
                {
                    text: "ID",
                    value: "id",
                    editable: false,
                    visible: false,
                    isId: true,
                },
                { text: "BTN", value: "btn", query: false, slot: true },
                {
                    text: "First Name",
                    value: "ins_first_name",
                    mandatory: true,
                },
                {
                    text: "Middle Name",
                    value: "ins_middle_name",
                    mandatory: true,
                },
                { text: "Last Name", value: "ins_last_name", mandatory: true },
                {
                    text: "Address 1",
                    value: "insured_address1",
                    mandatory: true,
                },
                { text: "Address 2", value: "insured_address2" },
                {
                    text: "Relationship",
                    value: "relationship",
                    type: "radio",
                    items: ["Self", "Parent", "Child", "Spouse", "Other"],
                    searchable: true,
                    mandatory: true,
                    fieldOptions: { column: false },
                },
                {
                    text: "Insurance Company",
                    value: "InsuranceCompany.name",
                    type: "autocomplete",
                    model: "InsuranceCompany",
                    modelId: "company_id",
                    modelFields: { value: "id", text: "name" },
                    searchable: true,
                    mandatory: false,
                    sm: 12,
                    md: 12,
                },
                {
                    text: "Deleted",
                    value: "deleted",
                    type: "date",
                    mandatory: false,
                },
                /*{ text: '', value: 'data-table-expand', query:false} If you want expander icon not in front*/
            ],
            headers1: [
                { text: "ID", value: "id", editable: false },
                { text: "First Name", value: "ins_first_name" },
                { text: "Middle Name", value: "ins_middle_name" },
                { text: "Last Name", value: "ins_last_name" },
                { text: "DOB", value: "dob" },
            ],
            headers2: [
                { text: "ID", value: "id", editable: false },
                { text: "Type", value: "type" },
                { text: "Status", value: "status" },
            ],
        };
    },
};
</script>