<template>
    <div :class="mainClass">
        <div class="v-input__control">
            <div class="v-input__slot">
                <fieldset aria-hidden="true" v-if="outlined">
                    <!--<legend :style="focused ? {width: '62px'} : {width: '0px'}">-->
                    <legend :style="{width: labelWidth+'px'}">
                        <span>&ZeroWidthSpace;</span>
                    </legend>
                </fieldset>
                <div class="v-text-field__slot">
                    <label
                        v-if="label"
                        :class="labelClass"
                        style="left: 0px; right: auto; position: absolute;"
                        ref="label"
                        v-resize="onLabelResize"
                    >{{label}}</label>
                    <div ref="element" style="width:100%;padding:8px 0 8px 0"></div>
                </div>
            </div>
            <div v-if="!hideDetails" class="v-text-field__details">
                <div class="v-messages theme--light">
                    <div v-if="error" class="v-messages__wrapper">{{error.message}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            focused: false,
            labelWidth: 0,
            error: {},
        };
    },
    watch: {
        element(n) {
            if (n instanceof Object) {
                this.init(n);
            }
        },
    },
    computed: {
        mainClass() {
            let classes = [
                "v-input",
                "v-text-field",
                "v-text-field--is-booted",
            ];

            if (this.dense) classes.push("v-input--dense");

            if (this.light) classes.push("theme--light");
            if (this.dark) classes.push("theme--dark");

            if (this.outlined) {
                classes.push("v-text-field--outlined");
                classes.push("v-text-field--enclosed");
            }

            if (this.focused) {
                classes.push("v-input--is-focused");
                classes.push("primary--text");
            }
            return classes;
        },
        labelClass() {
            let classes = ["v-label", "v-label--active"]; //v-label--active' keeps it above

            if (this.light) classes.push("theme--light");
            if (this.dark) classes.push("theme--dark");

            if (this.focused) {
                classes.push("v-label--active");
                classes.push("primary--text");
            }

            return classes;
        },
    },
    methods: {
        init(strypeElement) {
            strypeElement.mount(this.$refs.element);
            strypeElement.on("focus", () => {
                this.focused = true;
            });
            strypeElement.on("blur", () => {
                this.focused = false;
            });
            strypeElement.on("change", (event) => {
                this.error = event.error;
                return this.$emit("change", event);
            });
            strypeElement.on("click", (event) => this.$emit("click", event));
            strypeElement.on("ready", (event) => this.$emit("ready", event));
            strypeElement.on("escape", (event) => this.$emit("escape", event));
        },
        onLabelResize() {
            let scale = window.devicePixelRatio;
            this.labelWidth = this.$refs.label.clientWidth / scale;
        },
    },
    props: {
        element: {
            type: Object,
            //required:true,
        },
        outlined: Boolean,
        dark: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: true,
        },
        label: String,
        dense: Boolean,
        hideDetails: [Boolean, String],
    },
    mounted() {
        if (this.element instanceof Object) this.init(this.element);
    },
};
</script>